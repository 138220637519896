


  .inputBoxWidth, select option {
    width: 200px;
    border-color: white !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
  }
  .inputBoxWidth option {
    width: 200px;
    border-color: white !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: black !important;
  }

  button:hover {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
    box-shadow: none !important;
    outline: none !important;
  }

  

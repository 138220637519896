.boxRadius {
    border-radius: 30px;
}

.scaleUp {
    transition: transform 0.5s ease;
  }
  .scaleUp:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    .resPadTop {
      padding-top: 40px !important;}
      .resHeading {
        font-size: var(--font-size-16xl)!important;
      }
      .resText {
        font-size: var(--font-size-xl)!important;
      }
    
  }
  
.scaleUp {
    transition: transform 0.5s ease;
  }
  .scaleUp:hover {
    transform: scale(1.1);
  }
  .navbar-toggler-icon {
    background-color: white;
  }

  .logo {
    filter: brightness(0) invert(1);
  }
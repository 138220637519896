.sidebar {
    background-color: black;
    height: 100vh;
    display: flex;
    justify-content: start;
    align-items: start;
    position: fixed;
    top: 0px;
   left: 0px;
  right: 0px;
  width: 30px;
  }

@media (max-width: 575.98px) {
  .sidebar {
    align-items: center;
    height: 48px;
  width: 100%;
  z-index: 99;
}
.menu-bars {
  font-size: 1rem !important;
}
.nav-menu {
  width: 100% !important;
}
}



  button:hover {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
    box-shadow: none !important;
    outline: none !important;
  }

  button, input {
    outline: none;
    box-shadow: none !important;
  }

  .form-check-input:checked {
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .form-check-input {
    border-color: none !important;
  }

.startBot:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28200, 200, 200, 1%29'/%3e%3c/svg%3e") !important;
}

  
  .menu-bars {
    /* margin-left: 2rem; */
    font-size: 2rem;
    background: none;
  }

  .fixed-top {
    top: auto;
  }
  
  .nav-menu {
    z-index: 100;
    background-color: black;
    width: 250px;
    height: 100vh; /* Adjust height to account for the sidebar */
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .sidebar-toggle {
    background-color: black;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .span-margin {
    margin-left: 16px;
  }
select option {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: white; /* Ensure text color is visible on the custom background */
  }

  .list-group-item {
    color: white;
    background-color: transparent !important;
  }

  .list-group-item button:hover {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
    box-shadow: none !important;
    outline: none !important;
  }